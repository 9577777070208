import { parseErrorString } from "hyper-utils";
import React from "react";

interface IErrorPageProps {
  ex: any;
}
export default function ErrorPage(props: IErrorPageProps) {
  let message = parseErrorString(props.ex);
  return (
    <div
      className="flex-center"
      style={{ flexDirection: "column", height: "100vh", width: "100vw" }}
    >
      <div className="text-blue" style={{ fontSize: 40, fontWeight: "bold" }}>
        Oops. There is some problem with the system. Try Reloading page.{" "}
      </div>
      {message && (
        <div
          className="text-error"
          style={{ fontSize: 30, fontWeight: "bold" }}
        >
          Error: <b>{message}</b>
        </div>
      )}
    </div>
  );
}
