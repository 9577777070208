import { USER_ROLE } from "commonlib";
import config from "config";
import { LocalStorageUtils } from "hyper-utils";
import { AuthStore } from "stores/authStore";
import { ISystemUser } from "types/system-users";
import { createUUID } from "utils/uuidUtils";
import { getWindow, setWindow } from "utils/winUtils";
import { v4 } from "uuid";
import { clearAllCaches } from "./ReactQueryClient";

class AuthServices {
  static setToken(token: any) {
    LocalStorageUtils.lsSet(AuthServices.getOrCreateSessionID(), token);
  }

  static getToken() {
    return LocalStorageUtils.lsGet(AuthServices.getOrCreateSessionID());
  }

  static getOrCreateSessionID() {
    if (!getWindow()) return "";
    let sessionID = "";
    let cookies = document.cookie
      .split(";")
      .find((c) => c.includes("sessionID"));
    if (cookies) {
      sessionID = cookies.split("=")[1] || "";
    }
    if (getWindow() && !sessionID) {
      sessionID = v4();
      document.cookie = `sessionID=${sessionID}`;
    }
    return sessionID;
  }

  static onLogout(
    options: {
      shouldBroadcast: boolean;
      logoutReason?: string;
    } = { shouldBroadcast: true }
  ) {
    this.setToken(null);
    clearAllCaches();
    AuthStore.update((s) => {
      s.user = null;
      s.selectedMasterClient = null;
      s.logoutReason = options.logoutReason;
    });
    localStorage.clear();
    if (options.shouldBroadcast) {
      if (getWindow()?.loginChannel)
        getWindow().loginChannel.postMessage("logout");
    }
    // if (typeof window !== "undefined") window.location.reload();
  }

  static setUserData(objUser: ISystemUser) {
    LocalStorageUtils.lsSet("userData", objUser);
  }

  static getUserData() {
    if (!this.getToken()) return null;
    return LocalStorageUtils.lsGet("userData");
  }

  static removeToken() {
    LocalStorageUtils.lsDelete("token");
    LocalStorageUtils.lsDelete("userData");
  }

  static getSystemUserName() {
    return AuthStore.getRawState().user?.Username || "";
  }

  static getIsAdminUser() {
    return !!AuthStore.getRawState().user?.IsAdminUser;
  }

  static isSystemAdmin() {
    return (
      !!AuthStore.getRawState().user?.IsAdminUser &&
      AuthStore.getRawState().user?.Role === "SYSTEM_ADMIN"
    );
  }

  static is(
    role:
      | (keyof typeof USER_ROLE | "ADMIN")
      | (keyof typeof USER_ROLE | "ADMIN")[]
  ) {
    if (typeof role === "string") {
      role = [role];
    }
    let user = AuthStore.getRawState().user;
    if (!user) return false;
    if (user.IsAdminUser) {
      if (role.includes("ADMIN")) return true;
      return role.includes(user.Role as any);
    }
    if (!user.Access) return false;
    let accessKeys = Object.keys(user.Access);
    return accessKeys.some((key) => {
      return user && role.includes((user?.Access as any)[key].Role);
    });
  }

  static isOrganizationAdmin() {
    return (
      !!AuthStore.getRawState().user?.IsAdminUser &&
      AuthStore.getRawState().user?.Role === "ORGANIZATION_ADMIN"
    );
  }

  static isMasterAdminUser() {
    let masterClients = AuthStore.getRawState()?.user?.Access?.MasterClients;

    return Array.isArray(masterClients) && masterClients.length > 0;
  }

  static getDeviceID() {
    let deviceID = LocalStorageUtils.lsGet("deviceID");
    if (!deviceID && getWindow()) {
      deviceID = createUUID();
      localStorage.setItem("deviceID", deviceID);
    }
    return deviceID || "";
  }

  static isFeatureEnabled(feature?: string) {
    if (!feature) return false;
    let masterClient = AuthStore.getRawState().selectedMasterClient;
    if (!masterClient) return false;
    return masterClient.FeaturesEnabled?.includes(feature);
  }

  static getDefaultStateID() {
    return AuthStore.getRawState().user?.Preferences?.DefaultStateID;
  }

  static GetDocumentURL(DocumentID?: string, isPreview: boolean = false) {
    if (!DocumentID) return "";
    return `${
      config.ROOT_URL
    }/api/document-repository/download/${DocumentID}?token=${AuthServices.getToken()}${
      isPreview ? "&preview=true" : ""
    }`;
  }
}

setWindow("AuthServices", AuthServices);
export default AuthServices;
