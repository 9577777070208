import { CssBaseline, NoSsr, ThemeProvider } from "@mui/material";
import ErrorBoundary from "common/ErrorBoundary";
import LockScreen from "common/LockScreen/LockScreen";
import { ConfirmModalPanel } from "common/modal/ConfirmModal";
import NotiStackProvider from "common/notistack/NotiStackProvider";
import ReactQueryDevTools from "common/ReactQueryDevTools";
import colorList from "layouts/common/colors";
import ProductionModeRibbon from "layouts/common/ProductionModeRibbon";
import LoadScripts from "layouts/main/LoadScripts";
import type { AppProps } from "next/app";
import Head from "next/head";
import NextNProgress from "nextjs-progressbar";
import { QueryClientProvider } from "react-query";
import { queryClient } from "services/ReactQueryClient";
import OverPageLoader from "utils/loadingUtils";
import theme from "../layouts/main/theme";
import "../styles/data-table-overrides.css";
import "../styles/react-quill-overrides.css";
import "../styles/globals.css";
import "../styles/revenue-modal.css";
import "../styles/ribbon.css";
import "../styles/shorts.scss";
import "../styles/table.scss";
import NavigationListener from "layouts/common/NavigationListener";
import CssVars from "layouts/common/CssVars";
import AuthGuard from "guards/AuthGuard";

function MyApp({ Component, pageProps }: AppProps) {
  if (process.env.NEXT_PUBLIC_VERCEL_ENV === "preview") {
    theme.palette.primary.main = colorList.DeepOrange.medium;
    theme.palette.primary.light = colorList.DeepOrange.light;
    theme.palette.primary.dark = colorList.DeepOrange.dark;
    theme.palette.primary.contrastText = "#FFFFFF";
  }

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <CssVars />
        <NotiStackProvider>
          <OverPageLoader />
          <QueryClientProvider client={queryClient}>
            <NextNProgress
              color="var(--primary)"
              startPosition={0.3}
              stopDelayMs={200}
              height={2}
              showOnShallow={true}
            />

            <Head>
              <title>Advance Automation</title>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
            </Head>
            <Component {...pageProps} />
            <ConfirmModalPanel />
            <LoadScripts />
            <NoSsr>
              <AuthGuard NoLoginPage>
                <LockScreen  />
              </AuthGuard>
            </NoSsr>

            <ReactQueryDevTools />
            <ProductionModeRibbon />
            <NavigationListener />
          </QueryClientProvider>
        </NotiStackProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default MyApp;
