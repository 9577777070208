import { Icon, IconProps } from "@mui/material";
import { IMaterialIcon } from "data/material-icons";
import React from "react";

const RIcon = React.forwardRef(
  (
    props: Partial<IconProps> & {
      name: IMaterialIcon;
      variant?: "OUTLINED" | "CONTAINED";
      rounded?: boolean;
    },
    ref: any
  ) => {
    let style = { ...props.style };
    if (props.rounded) {
      style.borderRadius = 50;
    }
    return (
      <Icon
        ref={ref}
        className={
          props.variant === "OUTLINED" ? "material-symbols-outlined" : ""
        }
        {...props}
        style={style}
      >
        {props.name}
      </Icon>
    );
  }
);

export default RIcon;
